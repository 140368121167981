
import { Vue, Component, Prop } from 'vue-property-decorator';
import { marked } from 'marked';

@Component
export default class MarkdownView extends Vue {
  @Prop({ default: '' }) data!: string;

  content = '';

  mounted() {
    const removeUnreleased: RegExpMatchArray | null = this.data.match(/^## \[\d+.\d+.\d+\]\s{1,}[\s\S]{1,}/igm);
    const str = removeUnreleased?.[0] || '';
    this.content = marked.parse(str);
  }
}
