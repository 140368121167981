
import { Vue, Component } from 'vue-property-decorator';
import MarkdownView from '@/ui/components/components/MarkdownView.vue';
import changelogData from '../../../CHANGELOG.md';

@Component({
  components: {
    MarkdownView,
  },
})
export default class Changelog extends Vue {
  changelogData = changelogData;
}
